<template>
    <div>
        <el-upload
            :action="base_url + '/shop/api/oss/uploadImg?imgType=ONBASPLUMAIN'"
            :headers="header"
            list-type="picture-card"
            :limit="item.limit"
            :class="['upload-container', { 'has-picture': !fade }]"
            :multiple="false"
            :on-remove="handleRemove"
            :on-success="handlePictureSuccess"
            :on-error="handlePictureError"
            :before-upload="handleOversize"
            :show-file-list="item.files"
            :file-list="fileList"
        >
            <i class="el-icon-plus"></i>
        </el-upload>
    </div>
</template>

<script>
export default {
    data() {
        return {
            flag: false,
            dialogVisible: false,
            fileList: [],
            pictureList: [],
            fade: true,
            base_url: process.env.VUE_APP_BASE_URL || "",
            header: {
                Authorization: window.sessionStorage.getItem("SHZS-LOGIN-TOKEN")
            },
            imageType: ["image/jpeg", "image/png"]
        };
    },
    props: {
        item: {
            type: Object,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    },
    watch: {
        pictureList: {
            deep: true,
            handler() {
                if (this.item.limit > 1) {
                    this.$set(this.data, this.item.key, this.pictureList);
                } else {
                    let res = this.pictureList.length
                        ? this.pictureList[0]
                        : "";
                    this.$set(this.data, this.item.key, res);
                }
            }
        },
        data: {
            deep: true,
            handler() {
                this.initData();
            }
        }
    },
    mounted() {
        this.initData();
    },
    methods: {
        initData() {
            if (this.flag) return;
            this.flag = true;
            if (!this.data[this.item.key]) return;
            if (
                Object.prototype.toString.call(this.data[this.item.key]) ==
                "[object Array]"
            ) {
                this.data[this.item.key].map((item, index) => {
                    this.fileList.push({
                        name: "img" + index,
                        src: item,
                        url: item
                    });
                    this.pictureList.push(item);
                });
            } else {
                this.fileList.push({
                    name: "img",
                    src: this.data[this.item.key],
                    url: this.data[this.item.key]
                });
                this.pictureList.push(this.data[this.item.key]);
            }
            this.fade = this.pictureList.length < this.item.limit;
        },
        handleRemove(file, fileList) {
            let temp = [];
            fileList.map(item => {
                temp.push(item.url || item.response.model.path);

            });
            this.pictureList = temp;
            this.fileList = fileList;
            setTimeout(() => {
                this.fade = fileList.length < this.item.limit;
            }, 380);
        },
        handlePictureSuccess(response, file, fileList) {
            if (response.success && response.model.resCode === "SUCCESS") {
                this.fade = fileList.length < this.item.limit;
                this.pictureList.push(response.model.path);
                this.fileList.push({
                    name: "img" + fileList.length,
                    src: response.model.path,
                    url: response.model.path
                });
            } else {
                fileList.length--;
                this.fileList = fileList;
                this.fade = true;
                this.$message.error("图片上传失败");
            }
        },
        handlePictureError() {
            this.fade = true;
            this.$message.error("图片上传失败");
        },
        handleOversize(file) {
            this.fade = false;
            const isLt2M = file.size / 1024 / 1024 < 2;
            const isPic = this.imageType.includes(file.type);
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过2MB！");
            }
            if (!isPic) {
                this.$message.error("只接受jpg/png格式文件");
            }
            return isLt2M && isPic;
        }
    }
};
</script>

<style>
.upload-container.has-picture .el-upload--picture-card {
    display: none;
}
</style>
